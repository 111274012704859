import { css } from "@emotion/react";

import { BREAKPOINTS, COLORS, FLEX, HELPERS, SPACES, MIN_HEIGHT, VARS, STYLES } from "styles-js";

export const base = css`
background-color: white;
position: absolute;
left: 0;
width: 100%;
max-width: 480px;
top: ${VARS.headerHeight}px;
height: calc(100vh - ${VARS.headerHeight}px);
z-index: 900;
display: flex;
flex-direction: row;
align-items: stretch;
transform: translate(-100%, 0);
transition: transform 150ms ease-out;
box-shadow: 1px 3px 6px 2px rgba(0,0,0,.2);
.btn-content {
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;

export const menuVisible = css`
background-color: ${COLORS.paleGrey};
${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
  transform: translate(0, 0);
}
`;

export const inner = css`
display: flex;
flex-direction: column;
align-items: stretch;
width: 100%;
`;

export const customerCreditContainer = css`
  height: 56px;
  padding: 4px 8px 4px 12px;
  box-shadow: 0 3px 6px 0 rgba(40, 72, 103, 0.12);
  background-color: ${COLORS.marineBlue};
  display: flex;
  align-items: center;
`;
export const customerCreditContent = css`
width: 100%;
margin-left:10px;
display: flex;
align-items: center;
p{
  margin: 0 !important;
  line-height: normal;
  text-align: left;
  color: white;
}
p:first-of-type {
  font-size: 11px;
  font-weight: 500;
  opacity: 0.6;
  white-space: nowrap;
  padding-right: 5px;
}
p:last-child {
  font-size: 24px;
  font-weight: bold;
}
`;
export const creditButton = css`
  height: 32px;
  display:flex;
  justify-content: center;
  align-items:center;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 600;
`;
export const top = css`
flex: 1 1 auto;
width: 100%;
`;
export const topLinks = css`
width: 100%;
background-color: ${COLORS.paleGrey};
padding: 16px;
`;
export const firstLinks = css`
background-color: white;
`;

export const creaUnaButton = css`
  padding:0;
`;


//
// ─── NAV ────────────────────────────────────────────────────────────────────────
//

export const navLinks = css`
display: block;
margin-bottom: 16px;
font-size: 14px;
color: ${COLORS.slateGrey};
border-color: 1px solid ${COLORS.dark};
&:active,
&:hover {
  color: ${COLORS.primary};
  text-decoration: none;
  cursor: pointer;
}
`;

export const topNav = css`
padding: 16px;
margin: 0;
list-style: none;
ul {
  list-style: none;
}
li {
  a{
    ${navLinks}
    color: ${COLORS.darkTwo};
  }
}
  li:last-child {
    a{
      margin-bottom:0 !important;
    }
  }
`;
export const bottomNavMember = css`
background: white;
`;
export const bottomNav = css`
padding: 16px 16px 0px 16px;
margin: 0;
list-style: none;
ul {
  list-style: none;
}
li {
  a{
    ${navLinks}
  }
}
  li:last-child {
    a{
      margin-bottom:0 !important;
    }
  }
`;
export const line = css`
  width: 328px;
  height: 1px;
  margin: 24px 16px 12px 16px;
  background-color: ${COLORS.lighterGrey};
`;

export const logout = css`
margin-top: 0px;
padding: 0 !important;
a{
  margin-top:0 !important;
  padding-left: 16px;
}
`;

export const innerMenuTitle = css`
  display: flex;
  align-items: center;
  flex-direction: row;
  svg{
    margin-left 10px;
  }
`;

//
// ─── CONTACT CARDS ────────────────────────────────────────────────────────────────────────
//
export const contactContainer = css`
    width: 752px;
    height: 178px;
    border-radius: 2px;
    background-color: ${COLORS.paleGreyThree};
    position:absolute;
    z-index:9;
    left:50%;
    margin-left:-376px !important;
    top:280px;
    ${HELPERS.media.smallerThan.sm} {
        width: 312px;
        height: 252px;
        margin-left:-156px !important;
        top:240px;
    }
`;
export const contactWrapper = css`
width: 100%;
display:flex;
justify-content: space-evenly;
align-items: center;
margin:0 !important;
a{
  text-decoration: none;
}
`;


export const contactCardContent = css`
    margin: 8px 0;
`;
export const contactCard = css`
border-radius: 2px;
width: 240px;
border: solid 1px ${COLORS.paleGreyFour};
background-color: #ffffff;

h2{
    margin:0;
    font-weight: 800;
    font-size: 16px;
    text-align: left;
    color:  ${COLORS.slateGrey};
}
p{
    margin:0;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.42;
    text-align: left;
    color: ${COLORS.coolGrey};
}
${HELPERS.media.smallerThan.sm} {
  margin-bottom: 12px;
  width:156px;
}
`;

export const contactCardIcon = css`
    margin-right: 8px;
`;
export const contactCardWrapper = css`
    display:flex;
    align-items:center;
`;



export const backdrop = css`
background-color: rgba(200,200,200, 0.6);
position: absolute;
left: 0;
right: 0;
top: ${VARS.headerHeight}px;
bottom: 0;
height: calc(100vh - ${VARS.headerHeight}px);
z-index:899;
overflow: hidden;
width: 0%;
opacity: 0;
transition: transform 50ms ease-out;
`;
export const backDropVisible = css`
${HELPERS.media.smallerThan.custom(VARS.mobileBreakpoint)} {
  transform: scale(1);
  width: 100%;
  opacity: 1;
}
`;
